// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <Box sx={{ backgroundColor: "primary.main500" }}>
      <Container maxWidth="lg">
          {/* Text Block */}
          <Grow in={true} timeout={1200} appear={true}>
            <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              FREE ONLINE GAMES ON LUXADWIN
            </Typography>
            <Typography variant="h6" gutterBottom>
              Hi, gamer! Welcome to Luxadwin.com!
            </Typography>
            <Typography variant="body1" paragraph>
              Dive into our vast sea of online games available for you to play in single or multiplayer modes. They're perfectly compatible with your favorite mobile devices too. Whether you're using a laptop, smartphone, or tablet, we've got you covered. With offerings for players of all ages, Luxadwin ensures fun gaming experiences for everyone!
            </Typography>
            <Typography variant="body1" paragraph>
              NO DOWNLOADS, NO SUBSCRIPTIONS - JUST CLICK AND PLAY!
            </Typography>
            <Typography variant="body1" paragraph>
              Jump straight into the excitement of your beloved games without any need for downloads or subscriptions. Simply click on a game to start playing! Kick off with Luxadwin originals, including: Elemental Puzzles, Quest of Valor, Speedy Climb, and Stealthy Robber.
            </Typography>
            <Typography variant="body1" paragraph>
              Discover other hit titles like: Mystery Maze, Chess Champion, Egg Fighters, Quiz of Knowledge, and plenty more!
            </Typography>
            <Typography variant="body1" paragraph>
              BECOME PART OF A GAMING COMMUNITY!
            </Typography>
            <Typography variant="body1" paragraph>
              Looking to make friends while playing? Register an account and join games such as Kingdom Farm and Grand Dynasty. These titles boast vibrant communities with thousands of players and stand as some of the most iconic massive multiplayer online games ever, complete with fantastic social features for you to enjoy.
            </Typography>
            <Typography variant="body1" paragraph>
              OUR MOST POPULAR CATEGORIES!
            </Typography>
            <Typography variant="body1" paragraph>
              Luxadwin hosts a plethora of game genres catering to diverse preferences. Explore our exceptional puzzle games, solitaire challenges, and .io battles. For those seeking adrenaline, take on another player in our 2 player games, or race to the finish line in our exhilarating racing adventures.
            </Typography>
            </Box>
          </Grow>
      </Container>
    </Box>
  );
}

export default CTA;
