        import { Header, Hero, About, GamesList, CTA, Benefits, Contacts, CookieModal, NoMoney, DisclaimerModal } from "./components";
import { ThemeProvider } from '@mui/material';
import theme from "./theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Hero/>
        <GamesList/>
        <CTA/>
        <CookieModal/>
        <DisclaimerModal/>
      </ThemeProvider>
    </div>
  );
}

export default App;
