import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      dark: "#141521", // Original dark shade
      main: "#040407", // Vibrant primary color
      main600: "#08080d",
      main500: "#0c0d14", // Mid-dark shade
      main400: "#10111a", // Useful for gradients or shadows
      main300: "#141521", // Mid shade
      main200: "#43444d", // Lighter mid shade
      main100: "#72737a", // Near light shade
      main50: "#a1a1a6", // Very
    },
    text: {
      primary: "#E9EAEC",
    },
    secondary: {
      main: "#7ff8cc",
      secondary: "#90ADC6",
    },
    success: {
      main: "#C8E4B2",
    },
  },
  typography: {
    fontFamily: "Lilita One, sans-serif",
    body1: {
      fontSize: "18px",
    },
    h3: {
      fontSize: "1.7rem",
      "@media (min-width:600px)": {
        fontSize: "3rem",
      },
    },
    h4: {
      fontSize: "1.4rem",
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
    },
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
